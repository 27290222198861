import React, { useContext } from 'react';
import { AccountContext, HistoryContext, ModalContext } from '../../hooks';
import Login from '../forms/Login';
import Signup from '../forms/Signup';
import VerificationMessage from '../forms/VerificationMessage';
import axios from 'axios';
import Btn from '../controls/Btn';
import { ImgCloud, ImgPen, ImgTrophy, ImgChallenge } from '../../pictures/SVG';
import UploadAmbigram from '../forms/UploadAmbigram';
import ShareThought from '../forms/ShareThought';
import StartContest from '../forms/StartContest';
import StartChallenge from '../forms/StartChallenge';
import ModalMessage from '../modal/ModalMessage';

export default function ShareButtons({heading, feed="Main", buttons=['Ambigram','Thought','Challenge','Contest']}) {
    let { user, openChallenge, setOpenChallenge, postsRemaining, incrementPostsToday } = useContext(AccountContext);
    let Modals = useContext(ModalContext);
    let { forceNavigate } = useContext(HistoryContext);
    let login = () => Modals.create(localStorage.getItem('happy') ? Login : Signup, { feature: true });
    let callback = (f) => {
        if (user) {
            if (user.verified) {
                return f
            } else return () => Modals.create(VerificationMessage,{feature:true})
        } else return login;
    }

    if (!user || (user && user.birthday) > new Date().getTime() - (1000 * 60 * 60 * 24 * 30)) buttons = buttons.filter(b => b != 'Challenge');

    return <div className="shareButtons">
        {heading?<h4>Share</h4>:null}
        <ul>
            {buttons.indexOf('Ambigram')>=0?<li>
                <Btn
                    onClick={callback(() => {
                        if (postsRemaining() > 0) {
                            Modals.create(UploadAmbigram, {
                                callback: async post => {
                                    incrementPostsToday();
                                    let {data} = await axios.post('/api/upload-ambigram', {...post, user});
                                    if (data) {
                                        forceNavigate(post.feed=='Sketches' ? '/sketches' : '/posts');
                                        // localStorage.setItem('last')
                                        return true;
                                    } else {
                                        return false;
                                    }
                                },
                                feed
                            })
                        } else {
                            Modals.create(ModalMessage,{ title: 'Rate limit', body: 'You have exceeded your daily post limit! Please refrain from posting until tomorrow.' })
                        }
                    })}
                >
                    <ImgPen />
                    <div className="buttonLabel">{heading?"Ambigram":"Share " + (feed == 'Sketches' ? 'a sketch' : 'an ambigram')}</div>
                </Btn>
            </li>:null}
            {buttons.indexOf('Thought')>=0?<li>
                <Btn
                    onClick={callback(() => {
                        Modals.create(ShareThought, {
                            callback: async (post) => {
                                let { data } = await axios.post('/api/create-blog-post', { ...post, feed, user });
                                if (data) {
                                    forceNavigate(feed=='Sketches' ? '/sketches' : '/posts');
                                    return true;
                                } else {
                                    return false;
                                }
                            },
                            feed
                        })
                    })}
                >
                    <ImgCloud />
                    <div className="buttonLabel">{heading?"Text post":"Share a text post"}</div>
                </Btn>
            </li>:null}
            {buttons.indexOf('Challenge')>=0?<li>
                <Btn
                    onClick={() => {
                        if (openChallenge > new Date().getTime()) {
                            Modals.create(ModalMessage, { title: 'Start challenge', body: 'You may not run two challenges at once. Please wait until your last challenge is over!' })
                        } else {
                            callback(() => {
                                Modals.create(StartChallenge, {
                                    callback: async post => {
                                        let {data} = await axios.post('/api/start-challenge', {...post, feed, user});
                                        if (!data.error) {
                                            forceNavigate(data.url);
                                            setOpenChallenge(data.deathday);
                                        }
                                        return data
                                    },
                                    feed
                                })
                            })()
                        } 
                    }}
                >
                    <ImgChallenge />
                    <div className="buttonLabel">{heading?"Challenge":"Start a challenge!"}</div>
                </Btn>
            </li>:null}
            {user && user.admin && buttons.indexOf('Contest')>=0 ? <li>
                <Btn
                    onClick={callback(() => {
                        Modals.create(StartContest, {
                            callback: async (post) => {
                                let { data } = await axios.post('/api/create-contest', { ...post, user });
                                if (data) {
                                    forceNavigate('/contest');
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        })
                    })}
                >
                    <ImgTrophy />
                    <div className="buttonLabel">{heading?"Contest":"Start a contest"}</div>
                </Btn>
            </li>:null}
        </ul>
    </div>
}