import React, { memo } from 'react';

export default memo(function Linkify({ children }) {
    let re = /\b((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)\b/g;
    let match = children.match(re);

    if (!match) {
        return children;
    } else {
        let k = 0;
        let splitter = '\\/\\/\\/\\/\\/';
        match.forEach(m => children = children.replace(m, splitter));
        let split = children.split(splitter);
        let arr = [];
        for (let i = 0; i < match.length; i++){
            arr.push(<span key={k++}>{split[i]}</span>);
            if (match[i].indexOf('@')>=0) {
                arr.push(<a key={k++} href={'mailto: '+match[i]}>{match[i]}</a>)
            } else {
                let relative = match[i].indexOf('//') < 0;
                arr.push(<a key={k++} href={relative?'//'+match[i]:match[i]} target="_blank" rel="noopener noreferrer external">{match[i]}</a>)
            }
        }
        arr.push(<span key={k++}>{split[split.length-1]}</span>);
        return arr;
    }
})