import React, { useState, useContext, useRef } from 'react';
import Pages from '../posts/Pages'
import { HistoryContext, useTimeout } from '../../hooks';
import Button from '../controls/Button';
import SelectDropdown from '../controls/SelectDropdown';
import Expand from '../controls/Expand';

export default function Glyphs({page, term1 = '', term2 = '', type: startingType = 'All'}) {
    let [o1, setO1] = useState(term1);
    let [o2, setO2] = useState(term2);
    let { noNavigate } = useContext(HistoryContext);
    let [type, setType] = useState(startingType);
    let [loading, setLoading] = useState(false);
    let [search, setSearch] = useState(term1 && term2 ? 1 : 0);
    let { doTimeout, undoTimeout } = useTimeout();
    let timeout = useRef();

    let T = [o1, o2];
    T = T.sort();
    let T1 = T[0].toUpperCase();
    let T2 = T[1].toUpperCase();

    let query = type == 'All' ? { visGlyphs:true, $or: [{ type: 'Ambigram' }, { type: 'Entry' }, { type: 'ChallengeEntry' }], glyphs: T1 + '/' + T2 } : { visGlyphs: true, style: type, $or: [{ type: 'Ambigram' }, { type: 'Entry' }, { type: 'ChallengeEntry' }], glyphs: T1 + '/' + T2 }
    
    if (T1.indexOf('.') >= 0 || T2.indexOf('.') >= 0 || T1.indexOf('!') >= 0 || T2.indexOf('!') >= 0) {
        T1 = T1.replace(/\./g, "[^\\/]").replace(/\!/g, "[^\\/]+");
        T2 = T2.replace(/\./g, "[^\\/]").replace(/\!/g, "[^\\/]+");
        console.log('r1',`^${T1}\\/${T2}$`)
        console.log('r2',`^${T2}\\/${T1}$`)
        let glyphQuery = {
            $or: [
                { glyphs: { $regex: `^${T1}\\/${T2}$`, $options: '<options>' } },
                { glyphs: { $regex: `^${T2}\\/${T1}$`, $options: '<options>' } },
            ]
        }

        query = type == 'All' ?
            { visGlyphs: true, $or: [{ type: 'Ambigram' }, { type: 'Entry' }, { type: 'ChallengeEntry' }], ...glyphQuery } :
            { visGlyphs: true, style: type, $and:[{$or: [{ type: 'Ambigram' }, { type: 'Entry' }, { type: 'ChallengeEntry' }]}], ...glyphQuery }
        
    }

    return <div className="Panel GlyphSearch centrepanel">
        <h1>Search glyphs</h1>
        <p>A glyph consists of 2 orientations - one which rotates (or mirrors) to take the form of the other.</p>
        <p>Use the text fields below to specify a glyph, and find ambigrams continaing it!</p>
        <Expand label="Click here to view advanced search features">
            <h4 className="lilmb">Full stop '.' and exclamation mark '!'</h4>
            <p>Use a full stop '.' to match any single character. For example, 'A' in the first box and '.' in the second box will find glyphs with 'A' on one side and any single character on the other.</p>
            <p>Employ an exclamation mark '!' to match a string of characters of any length. 'A' in the first box and '!' in the second box will find glyphs with 'A' on one side and any string of characters on the other.</p>
            <h4 className="lilmb">Complex usage</h4>
            <p>The full stop '.' and exclamation mark '!' can be combined with other letters to create complex search queries.</p>
            <p>For example, '!A' in the first box and 'B..' in the second box will find glyphs where the first orientation is any string of characters ending in 'A', and the second orientation is 'B' followed by any two characters.</p>
            <p>You can imagine creating quite complex queries - for example, 'A!B...C' would equate to a string that starts with 'A' and ends with a 'C', with a 'B' being separated from the 'C' with any 3 characters, and any number of characters between the A and the B.</p>
            <p>Have fun crafting your query!</p>
        </Expand>

        <div className="searchBit">
            <div className="inputContainer">
                <div className="inputBit">
                    <label>
                        Orientation 1
                        <input
                            type="text"
                            value={o1}
                            onKeyDown={e => {
                                if (e.key == " ") {
                                    e.preventDefault();
                                }
                            }}
                            onChange={e=>setO1(e.target.value)}
                        />
                    </label>
                    <label>
                        Orientation 2
                        <input
                            type="text"
                            value={o2}
                            onKeyDown={e => {
                                if (e.key == " ") {
                                    e.preventDefault();
                                }
                            }}
                            onChange={e=>setO2(e.target.value)}
                        />
                    </label>
                </div>
                <div className="options">
                    
                    <label >
                        Animation:  <SelectDropdown
                            selected={type}
                            setSelected={setType}
                            disabled={loading}
                            options={[
                                'All',
                                'Rotational',
                                'Mirror',
                                'Lake'
                            ]}
                        />
                    </label>

                    <br/>
                    <br/>


                    <Button disabled={!o2 && !o1} onClick={() => {
                        setLoading(true);
                        undoTimeout(timeout.current);
                        timeout.current = doTimeout(() => {
                            let t1 = o1;
                            let t2 = o2;
                            if (o1 && !o2) t2 = o1;
                            if (!o1 && o2) t1 = o2;
                            let T = [t1, t2];
                            T = T.sort();
                            t1 = T[0];
                            t2 = T[1];
                            noNavigate('/glyphs/' + type.toLowerCase() + '/' + encodeURIComponent(t1.toLowerCase()) + '/' + encodeURIComponent(t2.toLowerCase()));
                            setLoading(false);
                            setSearch(s=>s+1);
                        }, 0.1, 's');
                    }}>
                        Search
                    </Button>
                </div>
            </div>
        </div>
        
        {search ? <div className={"glyphResults"+(loading?' hidden':'')}><Pages
            key={search}
            query={query}
            // query={
            //     type == 'All' ?
            //         { visGlyphs:true, $or: [{ type: 'Ambigram' }, { type: 'Entry' }, { type: 'ChallengeEntry' }], glyphs: T1 + '/' + T2 }
            //         : { visGlyphs:true, style: type, $or: [{ type: 'Ambigram' }, { type: 'Entry' }, { type: 'ChallengeEntry' }], glyphs: T1 + '/' + T2 }
            // }
            startingPage={page}
            url={`/glyphs/${type.toLowerCase()}/${o1.toLowerCase()}/${o2.toLowerCase()}/`}
            noPosts={<h3 style={{ marginTop: '1rem' }}>No results!</h3>}
            countResults
        /></div>:null}
    </div>
}