import React, { useState, useRef, useEffect } from 'react';
import { useTimeout } from '../../hooks';

export default function Shrinker({ children, expanded, unrender, speed=0.3, animateOn, fade }) {
    let ref = useRef();
    let { doTimeout } = useTimeout();
    let [height, setHeight] = useState(expanded && !animateOn ? null : 0);
    let [shrunk, setShrunk] = useState(!expanded || animateOn);
    let [render, setRender] = useState(expanded || !unrender);

    function expandAction() {
        if (!ref.current) {
            doTimeout(() => {
                expandAction()
            },10)
        } else {
            setHeight(ref.current.clientHeight);
            doTimeout(() => {
                setHeight(null);
                setShrunk(false);
            },speed,'s')
        }
    }

    useEffect(() => {
        if (expanded) {
            if (unrender) setRender(true);
            doTimeout(() => {
                expandAction();
            },50)
        } else {
            setShrunk(true);
            setHeight(ref.current.clientHeight);
            doTimeout(() => {
                setHeight(0);
                if (unrender) doTimeout(() => {
                    setRender(false);
                },speed,'s')
            },50)
        }  
    },[expanded])

    return <div className={`Shrinker ${shrunk?'shrunk':''}`} style={{ height, transition: `height ${speed}s ease-in-out` }}>
        <div ref={ref} style={{ border: '0.1px solid rgba(0,0,0,0)'}}>
            {render?children:null}
        </div>
    </div>
}
