import React, { useContext } from 'react';
import Expand from '../../controls/Expand';
import { AccountContext } from '../../../hooks';
import { NavLink } from 'react-router-dom';

export default function SubmissionRules({ }) {
    let { subbed } = useContext(AccountContext);
    
    return <div className="Info Panel centrepanel">
        <h1>Submission rules</h1>
        <h4>Welcome to our ambigram contest!</h4>
        <p>These contests are open to everyone from newbies to old hands. After the contest has started, you will have 3 weeks to enter up to {subbed?'3':'2'} designs which relate in some way to the theme. When the 3 weeks are up, there will be 1 week of voting, where users will give ratings to each of the entries. Through the honourable process of democracy, a winner will be selected to win a prestigious gold medal!</p>
        <p>To ensure a fair and enjoyable experience for everyone, please follow these guidelines when submitting your entries:</p>
        <ul>
            <li><Expand label="Adhere to the theme">Submit ambigrams that adhere to the given theme for the contest. Themes are usually quite broad and I am usually quite lenient, but entries that are entirely unrelated will be disqualified.</Expand></li>
            <li><Expand label="No plagiarism">Only submit original artwork created by you. Plagiarism will not be tolerated, and any plagiarized entries will be disqualified. Using an online ambigram generator is strictly prohibited.</Expand></li>
            <li><Expand label="Be original">While you may submit old or previously publicised work, please refrain from submitting any ambigrams that have been submitted to a previous contest unless it has been modified or improved in some way.</Expand></li>
            <li><Expand label="Adhere to the deadline">Submit your entries before the specified deadline. Late submissions will not be considered for judging, no exceptions.</Expand></li>
            <li><Expand label={`Submit only ${subbed?3:2} entries`}>You may only submit {subbed?'3':'2'} entries. {subbed?null:<>Premium users may submit 3 entries - <NavLink to={'/subscription'}>click here to learn more!</NavLink></>}</Expand></li>
            <li><Expand label="Use an acceptable file format">Ensure your entries are in an acceptable format, i.e. jpg, png, bmp or gif, and do not exceed 1MB.</Expand></li>
            <li><Expand label="Respect the comment section">These contest posts might attract a lot of comments. For this reason, please refrain from posting spammy or irrelevant comments as there will be a lot of people getting notifications.</Expand></li>
            <li><Expand label="Try your hardest!">The contest is for your best work only. Although I am quite lenient, submissions with a clear lack of effort will be removed. If this happens, I’ll make sure to give you personal tips on how to improve.</Expand></li>
        </ul>
    </div>
}