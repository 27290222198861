import React, { useState, useRef, useContext, memo, useEffect } from 'react';
import { AccountContext, ToastContext, useForceRender, useAreYouSure } from '../../hooks';
import ProfilePic from '../user/ProfilePic';
import Username from '../user/Username';
import Btn from '../controls/Btn.js';
import TextArea from '../controls/TextArea';
import Toast from '../modal/Toast';
import ImgExpand from '../controls/ImgExpand';
import Shrinker from '../controls/Shrinker';
import Uploader from '../controls/Uploader';
import Thumbnail from '../controls/Thumbnail';
import Linkify from '../controls/Linkify';

import axios from 'axios';

let Comment = memo(({ comment, showImage = true, post, chat, onLoad  }) => {
    let [deleted, setDeleted] = useState(false);
    let [editing, setEditing] = useState(false);
    let { user, checkPermissions } = useContext(AccountContext);
    let Toasts = useContext(ToastContext);
    let { username, message, id } = comment;
    let picSplit = message.split('\\');
    let src;

    let [loadedPic, setLoadedPic] = useState();
    let [loadedUser, setLoadedUser] = useState();

    if (picSplit.length > 1) {
        message = picSplit[0];
        src = picSplit[1];
    }

    useEffect(() => {
        if (loadedPic && loadedUser) {
            if (onLoad) onLoad();
        }
    },[loadedPic, loadedUser])

    let [messsage, setMesssage] = useState(message);

    let me = user && (user.username == username);
    let areYouSure = useAreYouSure();

    let onEdit = post && (me || checkPermissions('Edit comments')) ? async c => {
        await axios.post('/api/edit-comment',{ user, post: {_id:post._id}, comment:c, path: src, id:comment.id });
        setMesssage(c);
        setEditing(false);
    } : chat && me ? async c => {
        await axios.post('/api/edit-message',{ user, chat: {_id:chat._id}, message:c, path: src, id:comment.id });
        setMesssage(c);
        setEditing(false);
    } : null

    let render =  <div className="comment">
        <ProfilePic width={30} username={username} square onLoad={()=>setLoadedPic(true)} />
        <div className="messsage">
            <div className="boddy">
                <Username username={username} onLoad={()=>setLoadedUser(true)} /> 
                <div className="msg"><Linkify>{messsage}</Linkify></div>
                {post||chat ? <>
                    {(post ? (me || checkPermissions('Edit comments')): me) ? <Btn 
                        className="editButton"
                        onClick={() => {
                            setEditing(true);
                        }}
                    >Edit</Btn> : null}
                    {(post ? (me || checkPermissions('Remove comments')): me) ? <Btn
                        colour="red"
                        onClick={async () => {
                            if (await areYouSure("Are you sure you want to delete this comment?")) {
                                setDeleted(true);
                                let { data } = post ? await axios.post('/api/delete-comment', { comment, post:{_id:post._id}, user }) : await axios.post('/api/delete-message', { message:comment, chat: {_id:chat._id}, user });
                                if (!data) {
                                    setDeleted(false);
                                    Toasts.create(Toast, { type:"error", text:"Server error - comment not deleted!" });
                                }
                            }
                        }}
                    >Delete</Btn> : null}
                </> : null}
            </div>
            {src && showImage ? <ImgExpand src={"/api/pictures/" + src} />:null}
        </div>
    </div>

    if ((post||chat) && (me || checkPermissions('Remove comments') || checkPermissions('Edit comments'))) {
        return <>
            <Shrinker expanded={!deleted && !editing}>
                {render}
            </Shrinker>
            <Shrinker expanded={editing} unrender>
                <WriteComment
                    addComment={() => { }}
                    editing post={post} chat={chat}
                    cancelEdit={() => setEditing(false)}
                    message={messsage}
                    onEdit={onEdit}
                />
            </Shrinker>
        </>
    } else {
        return render;
    }
})


function WriteComment({ post, chat, addComment, editing, cancelEdit, message='', onEdit, onChangeHeight }) {
    let { user } = useContext(AccountContext);
    let [comment, setComment] = useState(message);
    let [loadingSubmit, setLoadingSubmit] = useState(false);
    let [loadingUpload, setLoadingUpload] = useState(false);
    let Toasts = useContext(ToastContext);
    let [path, setPath] = useState('');
    let [removePic, setRemovePic] = useForceRender();
    let [error, setError] = useState('');

    async function submit() {
        if (editing) {
            if (comment.length > 0 && !error) {
                setLoadingSubmit(true);
                console.log(comment)
                if (onEdit) {
                    await onEdit(comment);
                } else {
                    Toasts.create(Toast, { preset: 'server error' });
                }
            }
        } else {
            if ((comment.length > 0 || path) && !error) {
                setLoadingSubmit(true);
                let {data} = post ? await axios.post('/api/comment', { comment, post: {_id:post._id}, user, path }) : await axios.post('/api/message', { message:comment, chat: {_id:chat._id}, user, path });
                if (data) {
                    addComment(data);
                    setComment('');
                    setRemovePic();
                } else {
                    setError('Server error!');
                    Toasts.create(Toast, { preset: 'server error' });
                }
                setLoadingSubmit(false);
                let textArea = document.getElementById(`writeComment_${post?post._id:chat._id}`);
                if (textArea) setTimeout(()=>textArea.focus(),1);
            }
        }
    }

    let uploader = useRef();
    let unfocusses = useRef(0);

    return <div className="writeComment">
        <ProfilePic width={30} username={user.username} square />
        <div className="inputBit">
            <TextArea
                id={`writeComment_${post ? post._id : chat._id}`}
                onChangeHeight={onChangeHeight}
                label={editing? "Edit your comment" : "Write a comment"}
                value={comment}
                setValue={setComment}
                hideLabel
                disabled={loadingSubmit}
                error={error}
                setError={setError}
                validation={user && user.admin ? () => null : () => {
                    if (comment.length > 1000) return "Comment too long!"
                }}
                onKeyDown={e => {
                    if (e.key == "Enter" && comment.length > 0) {
                        e.preventDefault();
                        submit();
                    }
                }}
                showErrors
                setFocussed={focus => {
                    if (!focus && cancelEdit) {
                        unfocusses.current++;
                        if (unfocusses.current > 1) cancelEdit();
                    }
                }}
            />
            <div className="buttons">
                {editing ? <Btn
                    className="marginLeft"
                    onClick={cancelEdit}
                    disabled={loadingSubmit}
                    colour='darkGrey'
                >
                    Cancel
                </Btn> : <>
                    <Uploader
                    setError={setError}
                    setPath={setPath}
                    // upload={uploadPic}
                    remove={removePic}
                    setLoading={setLoadingUpload}
                    setId={ce=>uploader.current = ce}
                />
                {path ? <>
                    <Thumbnail height={24} src={'/api/pictures/'+path}/>
                        <Btn
                            onClick={setRemovePic}
                            disabled={loadingSubmit}
                            loading={loadingUpload}
                            colour='red'
                            className="marginLeft"
                        >
                            Remove image
                        </Btn>
                    </> : <Btn
                            onClick={()=>document.getElementById(uploader.current).click()}
                            disabled={loadingSubmit}
                            loading={loadingUpload}
                            colour='darkGrey'
                            fast
                    >
                        Add image
                    </Btn>
                }</>}
                <Btn
                    className="marginLeft"
                    onClick={submit}
                    loading={loadingSubmit}
                    disabled={loadingUpload}
                    colour='darkGrey'
                >
                    {editing?'Save':post?'Comment':'Send'}
                </Btn>
            </div>
        </div>
    </div>
}

export {Comment, WriteComment}