import React, { useState,useRef, useEffect } from 'react';
import { useMount } from '../../hooks';

export default function Input({ label, littleLabel, icon, setError = () => { }, error, value, setValue, type = 'text', attempts, validation, disabled, red, onKeyDown, min, max, children }) {
    let { componentDidMount } = useMount();
    let [focussed, setFocussed] = useState(false);
    let input = useRef();
    let [lastError, setLastError] = useState('');

    componentDidMount(() => {
        if (document.activeElement === input.current) setFocussed(true);
    })

    useEffect(() => {
        if (validation) {
            let error = validation(value)
            setError(error);
        }
    }, [value, attempts])

    useEffect(() => {
        if (error) setLastError(error);
    },[error])

    useEffect(() => {
        input.current.disabled = disabled;
    },[disabled])

    let showErrors = attempts > 0;

    let labelClass = 'label';
    if (!focussed && value==='' && !children) labelClass += ' empty';
    if ((error && showErrors) || red) labelClass += ' red';
    if (littleLabel) labelClass += ' noLabel';

    return <div className={disabled?"disabled Input tb" : "Input tb"}>
        <label className={"textLabel"+(icon?' iconContainer':'')} >
            {icon ? icon:null}
            {label ? <div className={labelClass}>{label}</div> : null}
            <div className="innerInput">
                {children}
                <input 
                    ref={input}
                    type={type}
                    min={min}
                    max={max}
                    value={value}
                    onChange={e => {
                        let { value } = e.target;
                        setValue(value);
                    }}
                    onKeyDown={e => {
                        if (onKeyDown) onKeyDown(e);
                    }}
                    onFocus={()=>setFocussed(true)}
                    onBlur={() => setFocussed(false)}
                />
            </div>
        </label>
        {/* <br/> */}
        <div className={'underline'}>
            <div className={error && showErrors || red ? "dottedRed" : "dottedBlue"} />
            <div className={error && showErrors || red ? "solidRed" : "solidBlue"} style={{ width: focussed ? '100%' : '0%' }} />
            <div className={error && showErrors ? "error" : "hidden error"}>{lastError}</div>
        </div>

    </div>
}