import React, { useContext, useEffect, useState } from 'react';
import ProfilePic from '../user/ProfilePic';
import Username from '../user/Username';
import { AccountContext, ResponsiveContext, ToastContext, useDynamic } from '../../hooks';
import { dateString } from '../../functions';
import { ImgStar } from '../../pictures/SVG';
import { NavLink } from 'react-router-dom';
import Btn from '../controls/Btn';
import axios from 'axios';
import Toast from '../modal/Toast';

export default function UserPanel({ post, top, noUsername, contest3 }) {
    let { smallDesktop } = useContext(ResponsiveContext);
    let [small, setSmall] = useState(false);
    let { user } = useContext(AccountContext);
    let Toasts = useContext(ToastContext);

    let Dynamic = useDynamic('post', post._id);
    let SetHidden;
    if (Dynamic) SetHidden = Dynamic.SetHidden;
    
    useEffect(() => {
        setSmall(smallDesktop);
    }, [smallDesktop])

    if (post.type=="Entry" && post.rank < 3) {
        return <div className={("UserPanel"+(top?' top':''))+" medalContainer "+(post.rank==0?'first':post.rank==1?'second':post.rank==2?'third':null)}>
            <div className="medal pictureBit">
                <ProfilePic username={post.username} width={small || top ? 40 : 75} square={small || top} animate={false}/>
                <img className="med" />
            </div>
            <div className="textBit">
                {!contest3 && post.theme ? <>
                    <NavLink to={'/contest/' + post.theme.toLowerCase().replace(/ /g, '-')}>
                        <b>Contest: {post.theme}</b>
                        <h3>{post.rank == 0 ? "1st" : post.rank == 1 ? '2nd' : post.rank == 2 ? '3rd' : ''} place</h3>
                    </NavLink>
                </> : <h3>{post.rank == 0 ? "1st" : post.rank == 1 ? '2nd' : post.rank == 2 ? '3rd' : ''} place</h3>}
                <div className="usernameBit">
                    <Username username={post.username} />
                </div>
                {post.birthday ? <div className="date">{dateString(post.birthday)}</div> : null}
            </div>
            {post.stars ? <Stars n={post.stars}/> :null}
        </div>
    }

    let picSize = small||top?50:100

    // if (post.type == 'Duel') {
    //     return <div className={`UserPanel duelUserPanel ${top?'top':''}`}>
    //         <div className="pictureBit">
    //             <div className="splitProfile">
    //                 <div className="split">
    //                     <ProfilePic username={post.participants[1].username} width={picSize} square animate={false} />
    //                 </div>
    //                 <div className="split reveal">
    //                     <ProfilePic username={post.participants[0].username} width={picSize} square animate={false}/>
    //                 </div>
    //                 <div className="mask" style={{width:picSize,height:picSize}} />
    //             </div>
    //         </div>
    //         <div className="textBit">
    //             <div className="usernameBit">
    //                 <Username username={post.participants[0].username} />
    //                 {/* <div className='vs'>VS</div> */}
    //                 <Username username={post.participants[1].username} />
    //             </div>
    //             {post.birthday ? <div className="date">{dateString(post.birthday)}</div> : null}
    //         </div>
    //     </div>
    // }

    return <div className={`UserPanel ${top?'top':''} ${post.hidden?'hiddenPost':''}`}>
        <div className="pictureBit">
            <ProfilePic username={post.username} width={picSize} square={small||top} />
        </div>
        {noUsername ? null : <div className="textBit">
            {!contest3 && post.type == 'Entry' ? <>
                <NavLink to={'/contest/' + post.theme.toLowerCase().replace(/ /g, '-')}>
                    <b>Contest: {post.theme}</b>
                </NavLink>
            </> : null}
            <div className="usernameBit">
                <Username username={post.username} />
            </div>
            {post.birthday ? <div className="date">{dateString(post.birthday)}</div> : null}
        </div>}
        {post.stars ? <Stars n={post.stars} /> : null}
        {post.hidden? <div className="postHidden"><Btn className="lilmt" onClick={async () => {
                await axios.post('/api/unhide-post', { user, post:{_id:post._id} });
                Toasts.create(Toast, { text:"The post has been unhidden." });
        }}>HIDDEN</Btn></div>:user && user.admin ? <>
            {post.curated && !small ? <div className="good"><ImgStar /></div> : null}
            {post.noob && !small && post.feed != 'Sketches' && SetHidden ? <Btn className="lilmt" onClick={async () => {
                await axios.post('/api/unnoob-post', { user, post });
                Toasts.create(Toast, { text:"The post has been moved to the main feed." });
                SetHidden(true);
            }}>Move to main feed</Btn>:null}
        </> : null}
    </div>
}

function Stars({ n }) {
    let [hovered, setHovered] = useState(false);
    let floor = Math.floor(n);
    let rem = n - floor;
    let stars = [];
    for (let i = 0; i < floor; i++) {
        stars.push(<ImgStar key={i} />);
    }
    return <div className="stars" onMouseEnter={()=>setHovered(true)} onMouseLeave={()=>setHovered(false)}>
        {stars}
        {rem > 0 ? <div className="rem" style={{width:`calc(1rem * ${rem})`}}>
            <ImgStar/>
        </div> : null}
        <div className={hovered ? "tooltip" : "hidden tooltip"} >
            {Math.floor(n * 100) / 100} star{n==1?null:'s'}
        </div>
    </div>
}